<template>
	<div class="home">
		<p class="test" @click="test" v-if="current_user.corp_id=='dingeb18cb3ded3f0424'">调试</p>
		<div class="info">
			<div class="left">
				<img class="avatar" :src="current_user.avatar" alt="" v-if="current_user.avatar" />
				<span class="avatar" v-else>{{current_user.name.substr(-2)}}</span>
				<div class="role">
					<p class="role-name">
						<span class="name">{{current_user.name}}</span>
						<span class="app-admin" :style="{borderColor:app_admin_list.find(one=>one.value==current_user.app_admin)?.border_color,color:app_admin_list.find(one=>one.value==current_user.app_admin)?.color}">{{app_admin_list.find(one=>one.value==current_user.app_admin)?.name}}</span>
					</p>
					<p class="role-corp">
						<span class="corp_name">
							{{current_user.corp_name}}
						</span>
					</p>
				</div>
			</div>
			<div class="right">
				<span class="export icon-23" @click="toExport" v-if="current_user.app_admin!=3&&isPc"></span>
				<span class="setting icon-3" @click="toManage" v-if="current_user.app_admin!=3"></span>
			</div>
		</div>
		<div class="body">
			<div class="tab">
				<span class="tab-one" :class="{selected:tab=='two'}" @click="tabActive('two')">数据列表</span>
				<span class="tab-one" :class="{selected:tab=='one'}" @click="tabActive('one')">报警记录</span>
			</div>
			<div class="filter">
				<div class="left">
					<p v-show="current_user.app_admin==1" class="filter-company" @click="open('show')">
						<span class="company-name">{{companyList.find(one=>one.dept_id==filter_save.dept_id)?.corp_name}}</span>
						<span class="arrow icon-2"></span>
					</p>
				</div>
				<div class="right">
					<span class="icon icon-4" :class="{color:filter_save.time[0]||filter_save.time[1]}" @click="open('filter_time_show')"></span>
					<span class="icon icon-22" :class="{color:filter_save.device_id.length>0||filter_save.mapping_name.length>0||filter_save.type.length>0}" @click="open('filterShow')"></span>
					<!--<span class="search icon-5" v-show="!filter_save.search_show" @click="click_search"></span>-->
				</div>
				<div class="search-box" v-show="filter_save.search_show">
					<span class="search-icon icon-5"></span>
					<input ref="input" class="input" type="text" v-model="filter_save.name" placeholder="搜索数据/监控点" @input="()=>getList()"/>
					<span class="clear-icon icon-10" v-show="filter_save.name.length>0" @click="filter_save.name=''"></span>
					<span class="cancel" @click="filter_save.search_show=false">取消</span>
				</div>
			</div>
			<div class="list" v-show="tab=='one'" @scroll="(e)=>scroll(e,tab)">
				<div class="list-one" v-for="item in list.one.data">
					<p class="top">
						<span v-show="typeList.find(one=>item.type==one.value)" class="type" :style="color_change(item.type)">{{typeList.find(one=>item.type==one.value)?.name}}</span>
						<span class="title">{{item.warning_content}}</span>
					</p>
					<p class="device">
						监控点：{{item.device_info.name}}
					</p>
					<p class="bottom">
						<span class="processor">处理人：{{item.user_info.map(one=>one.name).join(',')}}</span>
						<span class="create_time">报警时间：{{item.CreatedAt}}</span>
					</p>
				</div>
				<no-data v-show="list.one.data.length==0&&!loading"></no-data>
				<loading v-show="loading"></loading>
			</div>
			<div class="list" v-show="tab=='two'" @scroll="(e)=>scroll(e,tab)">
				<div class="list-two" v-for="item in list.two.data" @click="toRealData(item)">
					<p class="top">
						<span class="create_time">
							<span v-show="typeList.find(one=>item.type==one.value)" class="type" :style="color_change(item.type)">{{typeList.find(one=>item.type==one.value)?.name}}</span>
							{{item.CreatedAt}}
						</span>
						<span class="arrow icon-6"></span>
					</p>
					<p class="device">
						监控点名称：{{item.device_info.name}}
					</p>
					<p class="bottom">
						<span class="type-one" v-for="one in item.data">{{one['key-text']}}={{one.value}}</span>
					</p>
				</div>
				<no-data v-show="list.two.data.length==0&&!loading"></no-data>
				<loading v-show="loading"></loading>
			</div>
		</div>
		<popup v-model="filter.show" title="企业列表">
			<template #content>
				<div class="company-list">
					<p class="list-one" :class="{selected:item.dept_id==filter_save.dept_id}" v-for="item in companyList" @click="clickItem(item)">
						<span class="corp-name">{{item.corp_name}}</span>
					</p>
					<no-data v-show="companyList.length==0"></no-data>
				</div>
			</template>
		</popup>
		<popup v-model="filter.filter_time_show" title="时间筛选" height="2rem">
			<template #content>
				<div class="datetime">
					<span class="placeholder" @click="showTimePicker(0)" v-if="!filter.time[0]">起始时间</span>
					<div class="time" @click="showTimePicker(0)" v-else>
						<span class="month_day_time">{{time_filter(0,'time')}}</span>
						<div class="week_year">
							<p class="week">{{time_filter(0,'week')}}</p>
							<p class="year">{{time_filter(0,'year')}}</p>
						</div>
					</div>
					<span class="interval"></span>
					<span class="placeholder" @click="showTimePicker(1)" v-if="!filter.time[1]">截止时间</span>
					<div class="time" @click="showTimePicker(1)" v-else>
						<span class="month_day_time">{{time_filter(1,'time')}}</span>
						<div class="week_year">
							<p class="week">{{time_filter(1,'week')}}</p>
							<p class="year">{{time_filter(1,'year')}}</p>
						</div>
					</div>
				</div>
			</template>
			<template #title_right>
				<!--<span @click="clear" class="clear" style="font-size: .14rem;font-weight:normal;color: #3396fb;cursor: pointer">重置</span>-->
				<p class="add-user" style="justify-content: center">
					<span class="btn" @click="init('time')">确定</span>
				</p>
			</template>
		</popup>
		<popup v-model="filter.time_show" height="38vh">
			<template #content>
				<van-datetime-picker
						v-model="filter.select_time.value"
						title="选择时间"
						@confirm="confirm"
						@cancel="cancel"
						:min-date="filter.select_time.min_date"
						:max-date="filter.select_time.max_date"
				/>
			</template>
		</popup>
		<popup v-model="filter.filterShow" height="45vh" title="筛选">
			<template #content>
				<div class="add-user">
					<div class="content">
						<div class="col">
							<div class="col-name">监测点筛选</div>
							<div class="col-content">
								<new-select ref="device_id" :multiple="true" v-model="filter.device_id" placeholder="请选择监控点" title="监控点筛选" :list="deviceList" @requestList="()=>getDeviceList()">
									<template #title_right="scope">
										<span class="btn" @click="selectConfirm(scope.value,'device_id')">确定<span v-show="scope.value.length">({{scope.value.length}})</span></span>
									</template>
								</new-select>
							</div>
						</div>
						<!--<div class="col">-->
							<!--<div class="col-name">污染物筛选</div>-->
							<!--<div class="col-content">-->
								<!--<new-select ref="mapping_name" :disabled="filter.device_id.length==0" :multiple="true" v-model="filter.mapping_name" placeholder="请选择污染物" title="污染物筛选" :list="mapList" :defaultProp="{name:'key_mapping',value:'key_mapping'}"  @requestList="()=>getMapList()" @change="()=>getList()">-->
									<!--<template #title_right="scope">-->
										<!--<span class="btn" @click="selectConfirm(scope.value,'mapping_name')">确定<span v-show="scope.value.length">({{scope.value.length}})</span></span>-->
									<!--</template>-->
								<!--</new-select>-->
							<!--</div>-->
						<!--</div>-->
						<div class="col">
							<div class="col-name">数据类型筛选</div>
							<div class="col-content">
								<new-select ref="type" :multiple="true" v-model="filter.type" placeholder="请选择数据类型" title="数据类型筛选" :list="typeList" :defaultProp="{name:'name',value:'value'}" @requestList="()=>getDeviceList()">
									<template #title_right="scope">
										<span class="btn" @click="selectConfirm(scope.value,'type')">确定<span v-show="scope.value.length">({{scope.value.length}})</span></span>
									</template>
								</new-select>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #title_right="scope">
				<p class="add-user" style="justify-content: center">
					<!--<span class="btn default" @click="reset">重置</span>-->
					<span class="btn" @click="init('condition')">确定</span>
				</p>
			</template>
		</popup>
		<p class="van-safe-area-bottom"></p>
	</div>
</template>

<script>
	import {ref,reactive,toRefs,defineComponent,onMounted,computed,nextTick} from 'vue'
	import {useRouter,useRoute} from "vue-router"
	import {Dialog,Toast} from "vant"
	import api from "@/api/methods/index"
	import myPublic from "@/utils/public"
	import {useStore} from "vuex"
	import * as dd from 'dingtalk-jsapi';
	export default defineComponent({
		name: "home",
		setup(props, ctx) {
			const router = useRouter();
			const route = useRoute();
			const store = useStore();
			const input = ref(null);
			const state = reactive({
				app_admin_list:[
					{
						name:'超级管理员',
						value:1,
						color:'#ffa61a',
						border_color:'#ffa61a',
					},{
						name:'管理员',
						value:2,
						color:'#ffa61a',
						border_color:'#ffa61a',
					},{
						name:'企业成员',
						value:3,
						color:'#999',
						border_color:'#999',
					},
				],
				tab:'two',
				list:{
					'one':{
						page:1,
						page_size:12,
						total:0,
						next_time:Date.now(),
						data:[]
					},
					'two':{
						page:1,
						page_size:12,
						total:0,
						next_time:Date.now(),
						data:[]
					}
				},
				companyList:[],
				filter:{
					dept_id:'',
					device_id:[],
					mapping_name:[],
					type:[],
					name:'',
					time:['',''],
					select_time:{
						type:0,
						value:'',
						min_date:'',
						max_date:''
					},
					search_show:false,
					time_show:false,
					filter_time_show:false,
					filterShow:false,
					show:false,
				},
				filter_save:{
					dept_id:'',
					device_id:[],
					mapping_name:[],
					type:[],
					name:'',
					time:['',''],
					select_time:{
						type:0,
						value:'',
						min_date:'',
						max_date:''
					}
				},
				deviceList:[],
				mapList:[],
				typeList:[
					{
						name:'实时数据',
						value:2011,
					},
					{
						name:'小时数据',
						value:2061,
					},
					{
						name:'天数据',
						value:2031,
					}
				],
				loading:false,
				isPc:dd.pc
			});
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			function color_change(type) {
				if(type==2011){
					return {
						color:'#1eaf93',
						backgroundColor:'#e9f7f5'
					}
				}else if(type==2061){
					return {
						color:'#2aa0f7',
						backgroundColor:'#eaf4fe'
					}
				}else{
					return {
						color:'#ff872e',
						backgroundColor:'#fff3eb'
					}
				}
			}
			function tabActive(type='one'){
				if(state.tab!=type){
					state.tab = type;
					getList();
				}
			}
			function scroll(e,type) {
				if (e.target.clientHeight + e.target.scrollTop + 1 > e.target.scrollHeight) {
					// console.log('project_scroll', e.target.scrollTop);
					if (Date.now() - state.list[type].next_time>500&&Math.ceil(state.list[type].total/state.list[type].page_size)>state.list[type].page) {
						state.list[type].next_time = Date.now();
						state.list[type].page++;
						getList(state.list[type].page);
					}
				}
			}
			function click_search() {
				state.filter.search_show=true;
				nextTick(()=>{
					input.value.focus();
				});
			}
			function clickItem(item){
				if(state.filter.dept_id != item.dept_id){
					state.filter.dept_id = item.dept_id;
					state.filter_save.dept_id = item.dept_id;
				}
				state.filter.show = false;
				getList();
			}
			function weekFormat(value) {
				switch(value){
					case 1:
						return "周一";
					case 2:
						return "周二";
					case 3:
						return "周三";
					case 4:
						return "周四";
					case 5:
						return "周五";
					case 6:
						return "周六";
					case 0:
						return "周日";
				}
			}
			function time_filter(index,type){
				let time = state.filter.time[index];
				if(type=='week'){
					return time ? weekFormat(new Date(time.replace(/-/g, '/')).getDay()) : ''
				}else if(type=='year'){
					return time ? new Date(time.replace(/-/g, '/')).getFullYear() : '';
				}else if(type=='time'){
					return time?time.substring(5,16):'';
				}
			}
			function showTimePicker(type) {
				state.filter.select_time.value = state.filter.time[type]?new Date(state.filter.time[type]):new Date();
				state.filter.select_time.type = type;
				if (type==0){
					state.filter.select_time.min_date = calc_interval(state.filter.select_time.value,-10,'year');
					state.filter.select_time.max_date = calc_interval(state.filter.select_time.value,10,'year');
					if(state.filter.time[1]){
						state.filter.select_time.max_date = new Date(state.filter.time[1]);
					}
				}
				if (type==1){
					state.filter.select_time.min_date = calc_interval(state.filter.select_time.value,-10,'year');
					state.filter.select_time.max_date = calc_interval(state.filter.select_time.value,10,'year');
					if(state.filter.time[0]){
						state.filter.select_time.min_date = new Date(state.filter.time[0]);
					}
				}
				state.filter.time_show = true;
			}
			function timeSelect(time) {
				time = time.replace(/-/g, '/');
				let start_time = '';
				let end_time = '';
				if (filter.select_time.type === 1) {
					start_time = filter.time[0].replace(/-/g, '/');
					if (new Date(time).getTime() > new Date(start_time).getTime()) {
						filter.time[1]=time.replace(/\//g, '-');
					} else {
						end_time = myPublic.dateFormat.format(time, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
						start_time = myPublic.dateFormat.format(new Date(end_time.replace(/-/g, '/')).getTime() - 3600000, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
						filter.time[1]=end_time.replace(/\//g, '-');
						filter.time[0]=start_time.replace(/\//g, '-');
					}
				} else if (filter.select_time.type === 0) {
					if (!filter.time[1]) {
						end_time = new Date(new Date(time).getTime() + 3600000).toString();
						end_time = myPublic.dateFormat.format(end_time, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
					} else {
						end_time = filter.time[1].replace(/-/g, '/');
						start_time = filter.time[0].replace(/-/g, '/');
						if (new Date(time).getTime() > new Date(end_time).getTime()) {
							let subtraction = new Date(time).getTime() - new Date(start_time).getTime();
							end_time = new Date(new Date(end_time).getTime() + subtraction).toString();
							end_time = myPublic.dateFormat.format(end_time, 'YYYY-MM-DD HH:mm').replace(/-/g, '/');
						}
					}
					filter.time[0]=time.replace(/\//g, '-');
					filter.time[1]=end_time.replace(/\//g, '-');
				}
			}
			function calc_interval(time,interval,type='year') {
				let dateTime = {
					year:0,
					month:0,
					date:0,
					hour:0,
					minute:0,
				};
				dateTime.year = new Date(time).getFullYear();
				dateTime.month = new Date(time).getMonth();
				dateTime.date = new Date(time).getDate();
				dateTime.hour = new Date(time).getHours();
				dateTime.minute = new Date(time).getMinutes();
				for (let i in dateTime){
					if(type==i){
						dateTime[i] += interval
					}
				}
				return new Date(dateTime.year,dateTime.month,dateTime.date,dateTime.hour,dateTime.minute);
			}
			function confirm(val){
				state.filter.time[state.filter.select_time.type] = myPublic.dateFormat.format(val,'YYYY/MM/DD HH:mm');
				if(state.filter.select_time.type==0){
					if(!state.filter.time[1]){
						state.filter.time[1] = myPublic.dateFormat.format(calc_interval(state.filter.time[0],1,'month'),'YYYY/MM/DD HH:mm');
					}else{
						if(Date.parse(state.filter.time[1])-Date.parse(state.filter.time[0])>30*24*60*60*1000){
							state.filter.time[1] = myPublic.dateFormat.format(calc_interval(state.filter.time[0],1,'month'),'YYYY/MM/DD HH:mm');
						}
					}
				}
				if(state.filter.select_time.type==1){
					if(!state.filter.time[0]){
						state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-1,'month'),'YYYY/MM/DD HH:mm');
					}else{
						if(Date.parse(state.filter.time[1])-Date.parse(state.filter.time[0])>31*24*60*60*1000){
							state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-1,'month'),'YYYY/MM/DD HH:mm');
						}
					}
				}
				state.filter.time_show = false;
				// getList();
			}
			function cancel(){
				state.filter.time_show = false;
			}
			function clear(){
				state.filter.time[1] = myPublic.dateFormat.format(new Date(),'YYYY/MM/DD HH:mm');
				state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-7,'date'),'YYYY/MM/DD HH:mm');
				state.filter.filter_time_show = false;
				// getList();
			}
			function getList(page=1){
				if(state.tab == 'one'){
					warningDataList(page);
				}else{
					processDataList(page);
				}
			}
			function processDataList(page=1) {
				let p ={
					page:page,
					page_size:state.list.two.page_size
				};
				if(state.filter_save.dept_id){
					p.dept_id = state.filter_save.dept_id;
				}
				if(state.filter_save.time[0]||state.filter_save.time[1]){
					p.start_time = state.filter_save.time[0];
					p.end_time = state.filter_save.time[1];
				}
				if(state.filter_save.device_id.length>0){
					p.device_id = state.filter_save.device_id;
				}
				if(state.filter_save.mapping_name.length>0){
					p.mapping_name = state.filter_save.mapping_name;
				}
				if(state.filter_save.type.length>0){
					p.type = state.filter_save.type;
				}
				state.list.two.page = page;
				if(page==1){
					state.list.two.data = []
				}
				state.loading = true;
				api.process.processDataList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.data = myPublic.isJsonString(item.data)?JSON.parse(item.data):[];
								item.CreatedAt = myPublic.dateFormat.format(item.CreatedAt,'YYYY-MM-DD HH:mm');
							});
							if(page==1){
								state.list.two.data = res.data.list;
							}else{
								state.list.two.data.push(...res.data.list);
							}
							state.list.two.total = res.data.total;
						}
					}
					state.loading = false;
				})
			}
			function warningDataList(page=1) {
				let p ={
					page:page,
					page_size:state.list.one.page_size
				};
				if(state.filter_save.dept_id){
					p.dept_id = state.filter_save.dept_id;
				}
				if(state.filter_save.time[0]||state.filter_save.time[1]){
					p.start_time = state.filter_save.time[0];
					p.end_time = state.filter_save.time[1];
				}
				if(state.filter_save.device_id.length>0){
					p.device_id = state.filter_save.device_id;
				}
				if(state.filter_save.mapping_name.length>0){
					p.mapping_name = state.filter_save.mapping_name;
				}
				if(state.filter.type.length>0){
					p.type = state.filter.type;
				}
				state.list.one.page = page;
				state.loading = true;
				api.process.warningDataList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								let notice_content = myPublic.isJsonString(item.notice_content)?JSON.parse(item.notice_content):[];
								let notice_user_info = item.notice_user_info.split(',');
								let user_info = [];
								item.mapping_name = '';
								item.warning_content = '';
								// console.log('notice_content',notice_content)
								if(notice_content.length>0){
									notice_content.forEach((one)=>{
										if(one.key=='报警名称：'){
											item.mapping_name = one.value;
										}else if(one.key=='报警条件：'){
											item.warning_content = one.value;
										}
									})
								}
								if(notice_user_info.length>0){
									res.data.user_info.forEach((one)=>{
										if(notice_user_info.find(o=>o==one.user_id)){
											user_info.push(one);
										}
									})
								}

								item.CreatedAt = myPublic.dateFormat.format(item.CreatedAt,'YYYY-MM-DD HH:mm');
								item.user_info = user_info;
							});
							if(page==1){
								state.list.one.data = res.data.list;
							}else{
								state.list.one.data.push(...res.data.list);
							}
							state.list.one.total = res.data.total;
						}
					}
					state.loading = false;
				})
			}
			function getCompanyList(page=1){
				let p ={
					page:page,
					page_size:999
				};
				return api.company.getCompanyList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							state.companyList = res.data.list;
							if(!state.filter_save.dept_id){
								if(current_user.value.dept_id){
									state.filter_save.dept_id = current_user.value.dept_id;
								}else{
									state.filter_save.dept_id = res.data.list[0]?.dept_id;
								}
							}
						}
					}
				})
			}
			function getDeviceList() {
				let p ={
					page:1,
					page_size:999
				};
				if(state.filter_save.dept_id){
					p.dept_id = state.filter_save.dept_id;
				}
				return api.device.deviceList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							state.deviceList = res.data.list;
						}
					}
					return true;
				})
			}
			function getMapList() {
				let p ={};
				if(state.filter_save.device_id.length>0){
					p.device_ids = state.filter_save.device_id;
				}else{
					return Toast.fail({
						message: "请先选择监控点!",
					});
				}
				return api.device.getMappingByIds(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data)){
							state.mapList = res.data;
						}
					}
					return true;
				})
			}
			function toRealData(item){
				let token =myPublic.getStorage('cf-token');
				if(dd.pc){
					let url = location.origin + location.pathname + location.search + `?token=${token}#/real-data?data_id=${item.ID}`;
					myPublic.openSlidePanel(url,'实时数据');
				}else{
					router.push({path:'/real-data',query:{
						data_id:item.ID
						}})
				}
			}
			function toManage(){
				let token =myPublic.getStorage('cf-token');
				if(dd.pc){
					let url = location.origin + location.pathname + location.search + `?token=${token}#/manage`;
					myPublic.openSlidePanel(url,'管理后台');
				}else{
					router.push({path:'/manage'})
				}
			}
			function toExport(){
				let token =myPublic.getStorage('cf-token');
				if(dd.pc){
					let url = location.origin + location.pathname + location.search + `?token=${token}#/export`;
					myPublic.openSlidePanel(url,'导出');
				}else{
					router.push({path:'/export'})
				}
			}
			function test(){
				let token =myPublic.getStorage('cf-token');
				let url = location.origin + location.pathname + location.search + `?token=${token}#/home`;
				window.open(url,'_blank');
			}
			function init(type,one='filter_save',two="filter",bool=true){
				if(type=='time'){
					state[one].time[0] = state[two].time[0];
					state[one].time[1] = state[two].time[1];
					state[one].select_time.type = state[two].select_time.type;
					state[one].select_time.value = state[two].select_time.value;
					state[one].select_time.min_date = state[two].select_time.min_date;
					state[one].select_time.max_date = state[two].select_time.max_date;
					state.filter.filter_time_show = false;
				}else{
					state[one].type = JSON.parse(JSON.stringify(state[two].type));
					state[one].device_id = JSON.parse(JSON.stringify(state[two].device_id));
					state.filter.filterShow = false;
				}
				if(bool){
					getList();
				}
			}
			function open(type){
				if(type=='filter_time_show'){
					init('time','filter','filter_save',false);
				}else{
					init('condition','filter','filter_save',false)
				}
				state.filter[type] = true;
			}
			onMounted(async ()=>{
				state.filter_save.time[1] = myPublic.dateFormat.format(new Date(),'YYYY/MM/DD HH:mm');
				state.filter_save.time[0] = myPublic.dateFormat.format(calc_interval(state.filter_save.time[1],-7,'date'),'YYYY/MM/DD HH:mm');
				state.filter.time[1] = myPublic.dateFormat.format(new Date(),'YYYY/MM/DD HH:mm');
				state.filter.time[0] = myPublic.dateFormat.format(calc_interval(state.filter.time[1],-7,'date'),'YYYY/MM/DD HH:mm');
				if(route.query.tab=='one'){
					state.tab = route.query.tab;
				}
				await getCompanyList();
				getDeviceList();
				getList();
			});
			return {
				...toRefs(state),
				input,
				current_user,
				tabActive,
				clickItem,
				showTimePicker,
				time_filter,
				confirm,
				cancel,
				clear,
				getList,
				click_search,
				scroll,
				toRealData,
				toManage,
				toExport,
				test,
				getDeviceList,
				getMapList,
				color_change,
				init,
				open
			};
		},
		methods:{
			selectConfirm(value,type) {
				this.filter[type] = value;
				this.$refs[type].blur();
				// this.getList();
			},
			reset() {
				this.filter.device_id = [];
				this.filter.type = [];
				this.filter.mapping_name = [];
				this.filterShow = false;
				this.getList();
			}
		},
		components:{
		}
	})
</script>
<style scoped lang="less">
	.home{
		position: relative;
		height: 100vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background-color: #fff;
		.info{
			width: calc(100% - .6rem);
			display: flex;
			height: 1.4rem;
			background:url("../assets/img/bg.svg") no-repeat center/cover;
			padding: .24rem .3rem 0 .3rem;
			overflow: hidden;
			.left{
				flex: 1;
				height: .54rem;
				display: inline-flex;
				overflow: hidden;
				.avatar{
					width: .5rem;
					height: .5rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					background-color: #3396fb;
					border-radius: 50%;
					border: 2px solid #fff;
					font-size: .12rem;
				}
				.role{
					flex: 1;
					display: flex;
					flex-direction: column;
					margin:0 .1rem;
					overflow: hidden;
					.role-name{
						flex: 1;
						display: flex;
						align-items: center;
						.name{
							min-width: 0;
							color: #fff;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: .2rem;
						}
						.app-admin{
							flex-shrink: 0;
							border-radius: 1em;
							border: 1px solid #fff440;
							color: #fff440;
							padding:.02rem .08rem;
							margin-left: .05rem;
							font-size: .12rem;
						}
					}
					.role-corp{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.corp_name{
							flex: 1;
							color: #a5d9f1;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
			}
			.right{
				flex-shrink: 0;
				height: .5rem;
				line-height: .5rem;
				color: #fff;
				font-size: .26rem;
				.export{
					cursor: pointer;
					margin: 0 .1rem;
					&:active{
						opacity: .8;
					}
				}
				.setting{
					cursor: pointer;
					&:active{
						opacity: .8;
					}
				}
			}
		}
		.body{
			position: absolute;
			top: 1.32rem;
			bottom: 0;
			display: flex;
			flex-direction: column;
			width: calc(100% - .3rem);
			background-color: #fff;
			border-top-left-radius:2em;
			border-top-right-radius:2em;
			overflow: hidden;
			padding: .15rem;
			.tab{
				flex-shrink: 0;
				display: flex;
				justify-content: space-between;
				background-color: #eef2f5;
				padding: .03rem;
				border-radius: .04rem;
				.tab-one{
					flex: 1;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					height: .34rem;
					border-radius: .04rem;
					cursor: pointer;
				}
				.selected{
					background-color: #fff;
				}
			}
			.filter{
				position: relative;
				width: 100%;
				display: flex;
				flex-shrink: 0;
				padding-top: .15rem;
				.left{
					flex: 1;
					display: flex;
					overflow: hidden;
					.filter-company{
						min-width: 0;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						cursor: pointer;
					}
				}
				.right{
					margin-left: .1rem;
					.icon{
						cursor: pointer;
						margin-left: .15rem;
					}
					.color{
						color: #3396fb;
					}
				}
				.search-box{
					position: absolute;
					top: .06rem;
					left: 0;
					right: 0;
					display: flex;
					align-items: center;
					z-index: 1;
					.search-icon{
						position: absolute;
						left: .08rem;
						font-size: .16rem;
						color: #3396fb;
					}
					.input{
						flex: 1;
						height: .34rem;
						border-radius: 2em;
						border: 1px solid #3396fb;
						padding:0 .25rem 0 .25rem;
					}
					.clear-icon{
						position: absolute;
						right: .65rem;
						font-size: .16rem;
						color: #ccc;
					}
					.cancel{
						width: .6rem;
						text-align: center;
						color: #3396fb;
					}
				}
			}
			.list{
				flex: 1;
				overflow-y: auto;
				margin-top: .1rem;
				.list-one{
					border-bottom: 1px solid #efefef;
					padding: .1rem 0;
					.top{
						display: flex;
						overflow: hidden;
						padding-bottom: .05rem;
						.type{
							flex-shrink: 0;
							line-height: .18rem;
							border-radius: .02rem;
							color: #2aa0f7;
							background-color: #eaf4fe;
							padding: 0 .05rem;
							font-size: .12rem;
						}
						.title{
							flex: 1;
							padding: 0 .1rem;
							line-height: .18rem;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: .14rem;
							font-weight: bolder;
						}
					}
					.device{
						line-height: .2rem;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						color: #939ba8;
						font-size: .12rem;
					}
					.bottom{
						display: flex;
						justify-content: space-between;
						line-height: .2rem;
						color: #939ba8;
						font-size: .12rem;
					}
				}
				.list-two{
					border-bottom: 1px solid #efefef;
					padding: .1rem 0;
					margin-right: .1rem;
					cursor: pointer;
					.top{
						display: flex;
						justify-content: space-between;
						overflow: hidden;
						padding-bottom: .05rem;
						padding-top: .05rem;
						.type{
							display: inline-flex;
							align-items: center;
							flex-shrink: 0;
							line-height: .18rem;
							border-radius: .04rem;
							color: #2aa0f7;
							background-color: #eaf4fe;
							padding: 0.02rem .08rem;
							font-size: .12rem;
							font-weight: normal;
							margin-right: .1rem;
						}
						.create_time{
							display: inline-flex;
							align-items: center;
							font-weight: bolder;
						}
						.arrow{
							display: inline-flex;
							align-items: center;
							font-weight: bolder;
							&:active{
								opacity: .8;
							}
						}
					}
					.device{
						line-height: .2rem;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						color: #939ba8;
						font-size: .12rem;
					}
					.bottom{
						display: flex;
						flex-wrap: wrap;
						color: #939ba8;
						font-size: .12rem;
						.type-one{
							flex-shrink: 0;
							line-height: .2rem;
							border-radius: .02rem;
							color: #939ba8;
							background-color: #eef2f5;
							padding: 0 .06rem;
							font-size: .12rem;
							margin-right: .05rem;
							margin-top: .05rem;
						}
					}
				}
			}
		}
		.company-list{
			height: 100%;
			padding: 0 .25rem;
			overflow-y: auto;
			.list-one{
				display: flex;
				align-items: center;
				height: .4rem;
				border-bottom: 1px solid #efefef;
				.corp-name{
					flex: 1;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
			.selected{
				.corp-name{
					color: #2aa0f7;
				}
			}
		}
		.datetime{
			display: flex;
			align-items: center;
			height: 1rem;
			padding: 0 .25rem;
			.time{
				flex: 1;
				display: inline-flex;
				justify-content: center;
				.month_day_time{
					float: left;
					width: .90rem;
					height: .14rem;
					border-radius:.04rem;
					text-align: center;
					line-height: .14rem;
					background-color:#3396fb;
					color: #fff;
					padding: .08rem 0;
					font-weight: 100;
					font-size: .14rem;
				}
				.week_year{
					float: left;
					text-align: center;
					margin-left: .005rem;
					.week{
						line-height: .16rem;
						font-size: .13rem;
					}
					.year{
						line-height: .15rem;
						font-size: .15rem;
					}
				}
			}
			.interval{
				position: relative;
				float: left;
				width: .5rem;
				text-align: center;
				&::after{
					content:'';
					position: absolute;
					left: 0;
					top: 0;
					width: .5rem;
					border-top:2px solid #ccc;
				}
			}
			.placeholder{
				flex: 1;
				display: inline-flex;
				justify-content: center;
				color: #999;
				font-size: .186rem;
			}
		}
	}
	.add-user{
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		.content{
			flex: 1;
			overflow-y: auto;
			.col{
				padding: .05rem .25rem .15rem .25rem;
				.col-name{

				}
				.col-content{
					padding-top: .05rem;
					display: flex;
					overflow: hidden;
					.col-content-top{
						flex: 1;
						display: flex;
						overflow: hidden;
						margin-bottom: .1rem;
					}
				}
			}
			.row{
				display: flex;
				align-items: center;
				justify-content: space-between;
				min-height: .2rem;
				border-bottom: 1px solid #efefef;
				overflow: hidden;
				margin: 0 .25rem;
				padding: .1rem 0;
				font-size: .12rem;
				.row-name{
					width: .8rem;
					color: #949aa8;
				}
				.row-content{
					flex: 1;
					display: inline-flex;
					flex-wrap: wrap;
				}
			}
			.vertical{
				flex-direction: column;
			}
			.notice-type{
				display: flex;
				padding-top: .1rem;
				.notice-one{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 1rem;
					height: .32rem;
					border-radius: .04rem;
					background-color: #eef2f5;
					color: #999;
					cursor: pointer;
				}
				.selected{
					background-color: #2aa0f7;
					color: #fff;
				}
			}
			.textarea{
				flex: 1;
				padding: 0.1rem;
				border-radius: 0.04rem;
				background-color: #eef2f5;
				resize: none;
			}
		}
		.opt{
			flex-shrink: 0;
			display: flex;
			align-items: center;
			min-height: .5rem;
			border-top: 1px solid #efefef;
			padding: 0 .15rem;;
		}
		.btn{
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: .6rem;
			height: .3rem;
			border-radius: .04rem;
			background-color: #2aa0f7;
			color: #fff;
			font-size: .12rem;
			border: 1px solid transparent;
			cursor: pointer;
			&:active{
				opacity: .8;
			}
		}
		.default{
			background-color: #fff;
			color: #2aa0f7;
			border: 1px solid #2aa0f7;
		}
		.danger{
			color: #f56c6c;
			background: #fff;
			border-color: #fbc4c4;
		}
	}
</style>
